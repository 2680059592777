import React, { Component } from "react";
import Header from "./Components/Header.js";
import Footer from "./Components/Footer.js";
import Form from "./Components/Form.js";
// import RegisterBanner from "./Components/RegisterBanner.js";
import Login from "./Components/Login.js";
import { BrowserRouter as Router } from "react-router-dom";
import { Switch, Route, Redirect } from "react-router-dom";




export class App extends Component {
  render() {
    return (
      <>
        <div className="mainHolder">
          <Router>
            <Route path="/" exact component={Login} />

        
              <Route
                path="/registration"
                exact
                component={Form}
                refresh={true}
              />
           
          </Router>
        </div>
        <Footer />
      </>
    );
  }
}

export default App;

// function App() {
//   return (

//   );
// }

// export default App;
