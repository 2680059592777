import React from "react";
import "./Login.css";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import NewHeader from "../Components/NewHeader.js"

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  input: {
    color: "#00AFB9",
  },
  paper: {
    position: "absolute",
    // width: "60%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "20px",
  },
}));

export default function Login() {
  const classes = useStyles();
  const history = useHistory();

  const [username, setUsername] = React.useState();
  const [password, setPassword] = React.useState();

  // Whole form Textfield
  const handleChangeFormUsername = (event) => {
    setUsername(event.target.value);
    console.log("username", event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
    console.log("passowd", event.target.value);
  };

  const login = () => {
    if (username === "admin" && password === "HuJykmsh@192") {
      // toast.success("Login Successful");

      localStorage.setItem("cookie", "superadmin")
    
      // setTimeout(() => {
       
      // }, 2000);

      setTimeout(() => {
        history.push("/registration");
    }, 1000)


    } else {
      toast.error("Incorrect username");
    }
  };

  return (
    <>
      <ToastContainer />
      <NewHeader/>
      <div className="logincontainer">
        <h1>Login</h1>
        <h5>Enter your login details below to continue</h5>

        <div className="pwdHolder">
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} sm={12}>
              <TextField
                id="standard-basic1"
                label="User Name "
                autoComplete="new-password"
                InputProps={{
                  className: classes.input,
                  form: {
                    autocomplete: 'off',
                  },
                }}
                className={classes.textField}
                required
                onChange={handleChangeFormUsername}
                name="username"
                // value={formstate.firstName}
                inputProps={{ maxLength: 40 }}
              />
            </Grid>

            <Grid item xs={12} md={12} sm={12}>
              <TextField
                id="standard-basic1"
                label="Password"
                type="password"
                autoComplete="new-password"
                InputProps={{
                  className: classes.input,
                  form: {
                    autocomplete: 'off',
                  },
                }}
                
                className={classes.textField}
                required
                onChange={handleChangePassword}
                name="password"
                // value={formstate.firstName}
                inputProps={{ maxLength: 40 }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="submit">
          <button className="btn" onClick={login}>
            Submit
          </button>
        </div>
      </div>
    </>
  );
}
