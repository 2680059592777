import React from "react";
import logo from "../assets/lab1.jpg";
import footerlogo from "../assets/applogo.png";
import footerlogoapp from "../assets/apptext.png";

export default function NewHeader() {
  return (
    <div>
      <div className="headerHolder">
        <div className="lefImage">
        <span>
            <img className="footerimgadj widthLog" src={footerlogo} />
          </span>
          <span>
            <img className="footerimgadj" src={footerlogoapp} />
          </span>
        </div>

        <div className="rightImage"></div>
      </div>
    </div>
  );
}
